import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Button,
  Card,
  Typography,
  CircularProgress,
  Box,
  ButtonGroup,
} from '@mui/material';
import StarRating from '../components/StarRating';
import { setAlert } from '../actions/alertActions';
import Message from '../components/Message';
import { submitSurvey } from '../actions/surveyActions';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Survey = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  let { id: sessionId } = useParams();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const masseuse = queryParams.get('masseuse');
  const masseuse_id = queryParams.get('masseuse_id');

  const searchParams = new URLSearchParams(location.search);

  const tempMasseuses = {};

  // Loop through all search parameters
  searchParams.forEach((value, key) => {
    // Check if the key matches the pattern for other masseuse data
    if (key.startsWith('other_masseuse_')) {
      // Extract the type ('name' or 'id') and the identifier number (e.g., '1' from 'other_masseuse_name_1')
      const parts = key.split('_');
      const identifier = parts[parts.length - 1];
      const type = parts[parts.length - 2];

      // Initialize an object for this identifier if it doesn't already exist
      if (!tempMasseuses[identifier]) {
        tempMasseuses[identifier] = {};
      }

      // Assign the value to the corresponding key ('name' or 'id') in our temporary object
      if (type === 'name') {
        tempMasseuses[identifier].name = value;
      } else if (type === 'id') {
        tempMasseuses[identifier].id = value;
      }
    }
  });

  // Convert the temporary object into the desired array format
  const otherMasseuses = Object.values(tempMasseuses);

  const [answers, setAnswers] = useState([]);
  const [otherAnswers, setOtherAnswers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const alert = useSelector((state) => state.alert);
  const { msg } = alert;

  const surveySubmit = useSelector((state) => state.surveySubmit);
  const {
    loading: loadingSurveySubmit,
    error: errorSurveySubmit,
    success: successSurveySubmit,
  } = surveySubmit;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!masseuse) {
      navigate('/session-review/');
    }
  }, []);

  useEffect(() => {
    let timer = 0;
    if (otherMasseuses.length > 0) {
      timer = 2000;
    }
    if (successSurveySubmit) {
      setTimeout(() => {
        navigate('/session-review/thank-you');
      }, timer);
    }
  }, [successSurveySubmit]);

  function handleAnswerChange(questionId, value) {
    // Check if the newStateValue already exists in the current state array
    const existingIndex = answers.findIndex(
      (obj) => obj.question === questionId
    );

    if (existingIndex !== -1) {
      // If the value already exists, update it
      const newArray = [...answers];
      newArray[existingIndex] = { question: questionId, value: value };
      setAnswers(newArray);
      // return newArray;
    } else {
      // If the value does not exist, add it to the end of the array
      setAnswers([...answers, { question: questionId, value: value }]);
      // return [...currentStateArray, newStateValue];
    }
  }

  function handleOtherAnswerChange(questionId, value, masseuseId) {
    // Check if the newStateValue already exists in the current state array
    const existingIndex = otherAnswers.findIndex(
      (obj) => obj.masseuseId === masseuseId
    );

    if (existingIndex !== -1) {
      // If the value already exists, update it
      const newArray = [...otherAnswers];
      newArray[existingIndex] = {
        masseuseId: masseuseId,
        answer: {
          question: questionId,
          value: value,
        },
      };
      setOtherAnswers(newArray);
      // return newArray;
    } else {
      // If the value does not exist, add it to the end of the array
      setOtherAnswers([
        ...otherAnswers,
        {
          masseuseId: masseuseId,
          answer: {
            question: questionId,
            value: value,
          },
        },
      ]);
      // return [...currentStateArray, newStateValue];
    }
  }

  const { isLoading, error, data } = useQuery(
    'surveyQuestions',
    fetchSurveyQuestions,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const adminUserLogin = useSelector((state) => state.adminUserLogin);
  const { adminUserInfo } = adminUserLogin;

  async function fetchSurveyQuestions() {
    const response = await axios.get(
      'https://mydynasty.ca/ext/api/v1/surveys/questions/',
      {
        headers: {
          Authorization: adminUserInfo.token,
        },
      }
    );
    return response.data;
  }

  const handleSubmit = () => {
    if (answers.length < 1 || otherAnswers.length < otherMasseuses.length) {
      dispatch(
        setAlert('Please rate all the masseuses before submitting', 'error')
      );
      return;
    }
    const missingAnswers = answers.filter(
      (answer) => answer.value === 0 || answer.value === null
    );
    if (missingAnswers.length > 0) {
      dispatch(
        setAlert('Please answer all questions before submitting', 'error')
      );
      return;
    } else {
      dispatch(submitSurvey(answers, sessionId, masseuse_id))
        .then(() => {
          otherAnswers.map((answer) =>
            dispatch(
              submitSurvey([answer.answer], sessionId, answer.masseuseId)
            )
          );
        })
        .catch((error) => {
          setErrorMessage(error);
        });
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Typography variant="h1" align="center" mt={6}>
          <CircularProgress size={'3rem'} thickness={5} />
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <p>Error: {error.message}</p>
      </Container>
    );
  }

  const YesNoButtons = [
    <Button key="1">Yes</Button>,
    <Button key="2">No</Button>,
  ];

  function getValueByQuestion(question) {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].question === question) {
        return answers[i].value;
      }
    }
    return null; // Return null if the question is not found in the array
  }

  return (
    <Container>
      <Card
        key={data[0].id}
        variant="outlined"
        style={{
          backgroundColor: `rgba(245, 243, 239, 0.4)`,
          padding: '1.5rem',
          border: 'none',
          marginBottom: '40px',
          marginTop: '50px',
        }}
      >
        <div style={{ margin: '10px' }}>
          <h2>
            {data[0].statement}{' '}
            <span
              style={{
                fontSize: '2rem',
                color: 'var(--primary-color)',
              }}
            >
              {masseuse.toUpperCase()}
            </span>
          </h2>
        </div>{' '}
        <StarRating
          handleAnswerChange={handleAnswerChange}
          questionId={data[0].id}
          masseuseId={masseuse_id}
        />
      </Card>
      {otherMasseuses.map((masseuse, i) => (
        <Card
          key={i}
          variant="outlined"
          style={{
            backgroundColor: `rgba(245, 243, 239, 0.4)`,
            padding: '1.5rem',
            border: 'none',
            marginBottom: '40px',
            marginTop: '50px',
          }}
        >
          <div style={{ margin: '10px' }}>
            <h2>
              {data[0].statement}{' '}
              <span
                style={{
                  fontSize: '2rem',
                  color: 'var(--primary-color)',
                }}
              >
                {masseuse.name.toUpperCase()}
              </span>
            </h2>
          </div>{' '}
          <StarRating
            handleAnswerChange={handleOtherAnswerChange}
            questionId={data[0].id}
            masseuseId={masseuse.id}
          />
        </Card>
      ))}

      <Accordion
        variant="outlined"
        sx={{
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          // className="glow"
        >
          Click here to share more about your experience 😊
        </AccordionSummary>
        <AccordionDetails>
          {data.map(
            (question) =>
              question.id !== 1 && (
                <Card
                  key={question.id}
                  variant="outlined"
                  style={{
                    backgroundColor: `rgba(245, 243, 239, 0.4)`,
                    padding: '1.5rem',
                    border: 'none',
                    marginBottom: '40px',
                  }}
                >
                  <div style={{ margin: '10px' }}>
                    <h2>{question.statement}</h2>
                  </div>{' '}
                  {/* Survey changes are done. Each question now has a 'kind' field that denotes the type of question. There are 5 types of questions allowed - rating 1-10, 3 choices 1-3, 5 choices: 1-5, Yes/No: 1-2, text: text. The wording for choices can be anything that the UI needs - only the value is stored (integer).  */}
                  {question.kind === 1 ? (
                    <StarRating
                      handleAnswerChange={handleAnswerChange}
                      questionId={question.id}
                    />
                  ) : question.kind === 2 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        '& > *': {
                          m: 1,
                        },
                      }}
                    >
                      <ButtonGroup size="large">
                        <Button
                          key="1"
                          onClick={() => handleAnswerChange(question.id, 1)}
                          variant={
                            getValueByQuestion(question.id) === 1
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          Yes
                        </Button>
                        <Button
                          key="2"
                          onClick={() => handleAnswerChange(question.id, 2)}
                          variant={
                            getValueByQuestion(question.id) === 2
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </Box>
                  ) : question.kind === 3 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                        '& > *': {
                          m: 1,
                        },
                      }}
                    >
                      <ButtonGroup size="large">
                        <Button
                          key="1"
                          onClick={() => handleAnswerChange(question.id, 1)}
                          variant={
                            getValueByQuestion(question.id) === 1
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          1-5 KM
                        </Button>
                        <Button
                          key="2"
                          onClick={() => handleAnswerChange(question.id, 2)}
                          variant={
                            getValueByQuestion(question.id) === 2
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          6-20 KM
                        </Button>
                        <Button
                          key="3"
                          onClick={() => handleAnswerChange(question.id, 3)}
                          variant={
                            getValueByQuestion(question.id) === 3
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation
                        >
                          Over 20 KM
                        </Button>
                      </ButtonGroup>
                    </Box>
                  ) : question.kind === 4 ? (
                    <p>Kind 4</p>
                  ) : question.kind === 5 ? (
                    <p>Kind 5</p>
                  ) : null}
                </Card>
              )
          )}
        </AccordionDetails>
      </Accordion>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        {errorMessage && <h1>{errorMessage}</h1>}
        <Link to={'/session-review'}>
          <Button variant="outlined" size="large">
            Cancel
          </Button>
        </Link>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          size="large"
          variant="contained"
          disableElevation
          // style={{ fontSize: '2rem' }}
          disabled={loadingSurveySubmit}
          onClick={handleSubmit}
        >
          {loadingSurveySubmit ? 'Submitting...' : 'Submit Review'}
        </Button>
      </div>
      {msg?.length > 0 && <Message />}
    </Container>
  );
};

export default Survey;
